<!--
 * @Author: 陈小豆
 * @Date: 2021-11-23 10:41:35
 * @LastEditors: 陈小豆
 * @LastEditTime: 2021-11-23 15:27:03
-->
<template>
  <div class="about">
    <img src="@/assets/about/about_1.png" />
    <img src="@/assets/about/about_2.png" />
    <img src="@/assets/about/about_3.png" />
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
